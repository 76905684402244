import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

const GlobalStyles = createGlobalStyle`
${normalize()}

:root {
  /* Primary colors */
  --red: hsl(10, 99%, 73%);
  --sand: hsl(32, 82%, 85%);
  --sandDark: hsl(29, 90%, 73%);

  /* Secondary colors */
  --purple: hsl(261, 84%, 63%);
  --purpleDark: hsl(274, 72%, 39%);
  --purpleDarker: hsl(278, 34%, 37%);
  --orange: hsl(19, 99%, 65%);
  --grayBlue: hsl(216, 49%, 60%);
  --grayBlueLight: hsl(221, 21%, 74%);
  --green: hsla(155, 63%, 38%, 1);
  --greenLight: hsla(150, 97%, 40%, 1);
  --grayBlueLight2: hsla(221, 21%, 74%, 0.61);
  --greenDark: hsl(170, 12%, 37%);
  --darkBlue: hsla(256, 79%, 48%, 1);

  --gray2: hsla(0, 0%, 59%, 1);
  --grayLight: hsl(0, 0%, 80%);
  --borderGray: #DEDEDE;
  --inputBackgroundGray: #F4F4F4;
  
  --white: #fff;
  --black: hsl(0, 0%,15%);
  --black20: hsla(0, 0%, 32%, 1);
  --black85: hsla(0, 0%, 87%, 1);
  
  --backgroundGray: hsl(0, 0%, 95%);

  /* Errors */
  --errorRed: hsla(353, 63%, 44%, 1);

  /* Fonts */
  --headingFontBold75: "Sequel100Wide-75", sans-serif;
  --headingFontBold65: "Sequel100Wide-65", sans-serif;
  --headingFontBold45: "Sequel100Wide-45", sans-serif;
  --bodyFont: "post_grotesk_regularregular", sans-serif;
  --bodyFontMedium: 'post_grotesk_mediummedium', sans-serif;
  --bodyFontBold: 'post_grotesk_boldbold', sans-serif;
  --ppEiko: 'pp_eikomedium', serif;
  --ppEikoThin: 'pp_eikothin', serif;

  /* Onboarding Screen - Phone number component styles */
  --PhoneInputCountryFlag-borderColor: none;
  --PhoneInputCountryFlag-height: 20px;
  --PhoneInputCountrySelectArrow-width: 0.6em;
  --PhoneInputCountrySelectArrow-marginLeft: 0.3em;
  /* --PhoneInputCountrySelectArrow-opacity: 0.8; */

  /* Box shadows */
  --boxShadowCard: 0px 4px 24px rgba(37, 37, 37, 0.08);
}

*, *:before, *:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

html {
  font-size: 62.5%;
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: var(--bodyFont);
  color: var(--textColor);
  background: var(--white);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

#__next {
  height: 100%;
  isolation: isolate;
}

h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizeLegibility;
  margin-top: 0;
}

h1 {
  font-family: var(--headingFontBold75);
  font-size: 3.2rem;
}

 h3, h4, h5, h6 {
  font-family: var(--headingFontBold45);
}

h2 {
  font-family: var(--headingFontBold65);
  font-size: 2rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.2rem;
}

p {
  text-rendering: optimizeLegibility;
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-top: 0;
  color: var(--black);
}

a {
  text-decoration: none;
}

img {
  display: block;
}

button {
  font-family: 'post_grotesk_mediummedium', sans-serif;

  span {
    display: block; 
    line-height: 1;
  }
}

/* YOUTUBE LITE */
.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
}
.yt-lite.lyt-activated::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: var(--aspect-ratio);
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%;
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
  border: none;
  cursor: pointer;
}
.yt-lite:hover > .lty-playbtn {
  background-color: red;
  opacity: 1;
}
.yt-lite > .lty-playbtn::before {
  content: "";
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 11px 0 11px 19px;
}
.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

/* React Masonry */
.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -8px;
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 8px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 40px;
  }

  @media (min-width: 700px) {
    .my-masonry-grid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: -40px;
      width: auto;
    }

    .my-masonry-grid_column {
      padding-left: 40px;
      background-clip: padding-box;
    }

    .my-masonry-grid_column > div {
      margin-bottom: 40px;
    }

    .my-masonry-grid-messages {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: -40px;
      width: auto;
    }

    .my-masonry-grid-messages_column {
      padding-left: 40px;
      background-clip: padding-box;
    }

    .my-masonry-grid-messages_column > div {
      margin-bottom: 40px;
    }
  }
`;

export default GlobalStyles;
