import React from "react";
import styled from "styled-components";
import Button from "../UI/Button";

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Logo
            src="/logos/logo-glyph.svg"
            alt=""
            style={{ marginBottom: 40 }}
          />
          <Title>Oops, there is an error!</Title>
          <Button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </Button>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: min(100% - 20px, 350px);
  margin: 0 auto;
`;

const Logo = styled.img`
  width: 70px;

  @media (min-width: 768px) {
    width: initial;
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 2.4rem;
  }
`;
