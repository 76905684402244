import { useState } from "react";
import Head from "next/head";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import dynamic from "next/dynamic";
import type { ReactElement, ReactNode } from "react";
import { SSRProvider } from "react-aria";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";

import ErrorBoundary from "@/components/ErrorBoundary";

import "../styles/fonts.css";
import GlobalStyles from "../styles/GlobalStyles";

const AmplitudeProvider = dynamic(
  () => import("@/utils/amplitude/AmplitudeProvider"),
  { ssr: false }
);

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: Record<string, any>;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <SSRProvider>
            <AmplitudeProvider>
              <ErrorBoundary>{<Component {...pageProps} />}</ErrorBoundary>
            </AmplitudeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </SSRProvider>
        </Hydrate>
      </QueryClientProvider>
      <GlobalStyles />
      <VercelAnalytics />
    </>
  );
}

export default MyApp;
