import * as React from 'react';
import { HTMLMotionProps, motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { FadingCircle } from 'better-react-spinkit';

import Spacer from '../Spacer';

type ExtendedProps = React.HTMLAttributes<HTMLButtonElement> &
  HTMLMotionProps<'button'>;

type Variants = 'primary' | 'secondary';
type IconPosition = 'left' | 'right';

interface Props extends ExtendedProps {
  variant?: Variants;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  loader?: {
    isLoading: boolean;
    text: string;
  };
}

interface StyleProps {
  variant?: Variants;
  disabled?: boolean;
  iconPosition?: IconPosition;
}

const variants: Record<Variants, Record<string, any>> = {
  primary: {
    background: 'var(--black)',
    color: 'var(--white)',
    border: 'none',
    disabled: {
      background: 'var(--gray2)',
      border: 'none',
      color: 'var(--white)',
    },
  },
  secondary: {
    background: 'var(--white)',
    color: 'var(--black)',
    border: '1px solid var(--black)',
    disabled: {
      background: 'none',
      border: '1px solid var(--gray2)',
      color: '#929292',
    },
  },
};

const Button: React.FC<Props> = ({
  children,
  variant = 'primary',
  icon,
  iconPosition = 'left',
  loader,
  disabled,
  ...props
}) => {
  if (loader?.isLoading) {
    return (
      <ButtonComponent
        variant={variant}
        disabled={disabled || loader?.isLoading}
        {...props}
      >
        {icon && (
          <IconWrapper iconPosition={iconPosition} role="img">
            {icon}
          </IconWrapper>
        )}
        <LoadingWrapper>
          {loader.text}
          <FadingCircle color={variants[variant].disabled.color} />
        </LoadingWrapper>
      </ButtonComponent>
    );
  }

  return (
    <ButtonComponent variant={variant} disabled={disabled} {...props}>
      {icon && (
        <>
          <IconWrapper iconPosition={iconPosition} role="img">
            {icon}
          </IconWrapper>
        </>
      )}
      {children}
    </ButtonComponent>
  );
};

// Base component
const ButtonComponent: React.FC<Props> = ({
  children,
  variant = 'primary',
  icon,
  iconPosition = 'left',
  loader,
  disabled,
  ...props
}) => {
  return (
    <ButtonStyled
      whileHover={{ scale: 1.005 }}
      whileTap={{ scale: 0.995 }}
      transition={{ type: 'spring', damping: 12 }}
      variant={variant}
      disabled={disabled}
      iconPosition={iconPosition}
      {...props}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;

const ButtonStyled = styled(motion.button)<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ variant }) => variant && variants[variant].border};
  border-radius: 2px;
  font-size: 1.4rem;
  background: ${({ variant }) => variant && variants[variant].background};
  text-transform: uppercase;
  color: ${({ variant }) => variant && variants[variant].color};
  padding: 16px 24px;
  letter-spacing: 0.14em;
  width: 100%;
  font-family: var(--bodyFontBold);
  cursor: pointer;
  line-height: 1;
  will-change: transform;
  height: 55px;
  white-space: nowrap;

  ${({ disabled, variant }) =>
    disabled &&
    variant &&
    css`
      pointer-events: none;
      background: ${variants[variant].disabled.background};
      border: ${variants[variant].disabled.border};
      color: ${variants[variant].disabled.color};
      cursor: not-allowed;
    `}
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const IconWrapper = styled.span`
  ${({ iconPosition }: { iconPosition: IconPosition }) => {
    if (iconPosition === 'left') {
      return css`
        margin-right: 10px;
      `;
    }
    return css`
      margin-left: 10px;
      order: 2;
    `;
  }}
`;
